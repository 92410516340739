// routeConstants.js

export const LOGIN_ROUTE = '/';
export const HOME_ROUTE = '/home';
export const RISK_ANALYSIS_ROUTE = '/ip';
export const OPD_ANALYSIS_ROUTE = '/op';
export const CAMP_ANALYSIS_ROUTE = '/camp';
export const SALES_DASHBOARD_ROUTE = '/sales-dashboard';
export const LEAD_ANALYSIS_ROUTE = '/leads-analysis';
export const FILE_UPLOAD_ROUTE = '/file-upload';
export const LIST_SALES_USER_ROUTE = '/user/sales'
export const ANALYTICS_ROUTE = '/analytics'
export const SALES_AGENT_ANALYTICS_ROUTE = '/analytics/sales_agent'
export const SEARCH_PATIENTS = '/patients/search'
export const ADMIN_IP_PATIENTS_LIST = '/patients/ip'
export const ADMIN_OP_PATIENTS_LIST = '/patients/op'
export const RECOMMENDATION_LIST = '/recommendations'
// ... define other routes as needed